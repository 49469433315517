import { LOGIN, STREAMING } from "../config";
import httpClient from "../http-client";

const AuthServices = {
  start_streaming: (data) =>
    httpClient.post(STREAMING, data).then((response) => response.data),
  stop_streaming: (data) =>
    httpClient.post(STREAMING, data).then((response) => response.data),
  login: (data) =>
    httpClient.post(LOGIN, data).then((response) => response.data),
};

export default AuthServices;
