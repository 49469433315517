import KinisisPlayer from "./Kinesis/KinisisPlayer";
import Login from "./login";
import { useState } from "react";

function getUserData() {
  try {
    const data = sessionStorage.getItem("user_data");
    if (data === null || data === undefined) {
      return false;
    }
    return JSON.parse(data);
  } catch (error) {
    return false;
  }
}

const version = process.env.REACT_APP_BUILD_VERSION || "Unknown Version";
const date = process.env.REACT_APP_BUILD_DATE || "Unknown Date";

function App() {
  const [login, setLogin] = useState(getUserData());
  return (
    <>
      {login ? (
        <KinisisPlayer setLogin={setLogin} />
      ) : (
        <Login setLogin={setLogin} />
      )}
      <footer className="fixed-bottom bg-light text-center py-2 border-top">
        <div className="container">
          <p className="mb-0">
            <strong>Version:</strong> {version} | <strong>Date:</strong>
            {date}
          </p>
        </div>
      </footer>
    </>
  );
}

export default App;
