import React, { useState } from "react";
import logger from "../lgger";
import AuthServices from "../api/services/auth-services";

const Login = ({ setLogin }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const data = await AuthServices.login({ email, password });
      sessionStorage.setItem("user_data", JSON.stringify(data));
      setLogin(data);
      setLoading(false);
      setErr("");
    } catch (error) {
      logger.error(error);
      setLoading(false);
      setErr(error?.data?.message);
    }
  };

  return (
    <div
      className="container d-flex justify-content-center align-items-center"
      style={{ height: "100vh" }}
    >
      <div className="card" style={{ width: "100%", maxWidth: "400px" }}>
        <div className="card-body">
          <h5 className="card-title text-center mb-4">
            Kinesis Video Stream (VOD)
          </h5>
          {err && (
            <div className="alert alert-danger" role="alert">
              {err}!
            </div>
          )}
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="email">Email address</label>
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                className="form-control"
                id="password"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>

            <button
              type="submit"
              className="btn btn-outline-secondary btn-block"
              disabled={loading}
            >
              Login
            </button>
          </form>
        </div>
      </div>
      {loading && (
        <div
          style={{
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            top: "0",
            left: "0",
            width: "100vw",
            height: "100vh",
            background: "#00000017",
          }}
        >
          <div className="spinner-border text-dark" role="status"></div>
        </div>
      )}
    </div>
  );
};

export default Login;
