import * as Viewer from "./viewer";
import { useEffect, useRef, useState } from "react";
import logger from "../lgger";
import AuthServices from "../api/services/auth-services";

function onStatsReport(report) {
  console._debug("[STATS]", Object.fromEntries([...report.entries()]));
}

function generateRandomString(length = 12) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

const formValues = (channelName) => {
  return {
    acceptHostCandidates: true,
    acceptPrflxCandidates: true,
    acceptRelayCandidates: true,
    acceptSrflxCandidates: true,
    acceptTcpCandidates: true,
    acceptUdpCandidates: true,
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    channelName: channelName,
    clientId: generateRandomString(),
    enableDQPmetrics: false,
    enableProfileTimeline: false,
    endpoint: null,
    forceSTUN: false,
    forceTURN: false,
    fullscreen: false,
    ingestMedia: false,
    natTraversalDisabled: false,
    openDataChannel: false,
    region: "us-west-2",
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    sendAudio: false,
    sendHostCandidates: true,
    sendPrflxCandidates: true,
    sendRelayCandidates: true,
    sendSrflxCandidates: true,
    sendTcpCandidates: true,
    sendUdpCandidates: true,
    sendVideo: true,
    sessionToken: null,
    showJSSButton: false,
    streamName: "",
    useTrickleICE: true,
    widescreen: true,
  };
};

function convertMacToLowerCase(macAddress) {
  return macAddress?.toLowerCase();
}

function KinisisPlayer({ setLogin }) {
  const videoRef = useRef(null);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [macid, setMacID] = useState("");
  const getChannelName = async () => {
    setLoading(true);
    try {
      const payload = {
        macid: convertMacToLowerCase(macid),
        camera: true,
        status: "sent",
        type: "kinesis",
        device_id: 1001,
        channel_arn: "",
      };
      const { body } = await AuthServices.start_streaming(payload);
      setData(body?.data);
      // setMacID("");
      setLoading(false);
    } catch (error) {
      logger.error(error);
      setLoading(false);
    }
  };

  const handleClose = async () => {
    setLoading(true);
    try {
      const payload = { ...data };
      payload.camera = false;
      await AuthServices.stop_streaming(payload);
      Viewer.stopViewer();
      setLoading(false);
      setData({});
    } catch (error) {
      logger.error(error);
      setLoading(false);
    }
  };

  const handleLogout = async () => {
    if (Object.keys(data)?.length > 0) {
      setLoading(true);
      try {
        const payload = { ...data };
        payload.camera = false;
        await AuthServices.stop_streaming(payload);
        Viewer.stopViewer();
        setLoading(false);
        sessionStorage.clear();
        setLogin(null);
      } catch (error) {
        logger.error(error);
        setLoading(false);
      }
    } else {
      sessionStorage.clear();
      setLogin(null);
    }
  };

  useEffect(() => {
    if (data?.channel_name) {
      Viewer.startViewer(
        null,
        videoRef.current,
        formValues(data?.channel_name),
        onStatsReport,
        null
      );
    }
  }, [data]);

  return (
    <>
      <nav
        className="navbar bg-dark border-bottom border-body"
        data-bs-theme="dark"
      >
        <div className="container-fluid py-1">
          <a className="navbar-brand"></a>
          <form className="d-flex" role="search">
            <button
              className="btn btn-outline-secondary btn-sm"
              onClick={handleLogout}
            >
              Logout
            </button>
          </form>
        </div>
      </nav>
      <div className="container">
        <h4 className="text-center my-3">Kinesis Video Stream (VOD)</h4>
        <div className="row justify-content-center">
          <div className="input-group my-3 w-50">
            <input
              value={macid}
              type="text"
              className="form-control"
              placeholder="Enter mac address"
              onChange={(e) => setMacID(e.target.value)}
            />
            <button
              onClick={getChannelName}
              className="btn btn-outline-secondary"
              type="button"
              id="button-addon2"
            >
              Start Viewer
            </button>
          </div>
        </div>

        <div className="border-1 my-1 row justify-content-center">
          <video
            width={450}
            height={250}
            ref={videoRef}
            autoPlay
            playsInline
            controls
          />
        </div>

        {data?.channel_name && (
          <div className="text-center my-2">
            <button
              onClick={handleClose}
              type="button"
              className="btn btn-outline-secondary"
            >
              Stop Viewer
            </button>
          </div>
        )}

        {loading && (
          <div
            style={{
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              top: "0",
              left: "0",
              width: "100vw",
              height: "100vh",
              background: "#00000017",
            }}
          >
            <div className="spinner-border text-white" role="status"></div>
          </div>
        )}
      </div>
    </>
  );
}
export default KinisisPlayer;
